import * as React from "react";
import { Row, Col } from 'react-bootstrap';
import axios from "axios";

const actionURL = 'https://montage.co.jp/php-mail/send-mail.php';

const ContactSend = ({ values, sentAction, failedAction }) => {

  React.useEffect(() => {
    let unmounted = false;
    const data = new FormData();
    for ( const key in values ) {
      data.append(key, values[key]);
    }
    //console.log(...data.entries());
    const source = axios.CancelToken.source();
    axios.post(actionURL, data, {
      headers: {'X-Requested-With': 'XMLHttpRequest'},
      responseType: 'text',
      timeout: 5000,
      cancelToken: source.token
    })
    .then((result) => {
      //console.log('then: ', result);
      if (!unmounted) {
        const resultData = JSON.parse(JSON.stringify(result.data));
        if (!!resultData.contact && !!resultData.confirm) {
          sentAction();
        } else {
          failedAction();
        }
      }
    })
    .catch(() => {
      if (!unmounted) {
        failedAction();
      }
    });
    return (() => {
      unmounted = true;
      source.cancel();
    });
  }, [values, sentAction, failedAction]);

  return (
    <Row>
      <Col>
        <p className="text-center">
          送信中...
        </p>
      </Col>
    </Row>
  );
};

export default ContactSend;

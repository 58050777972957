import { gsap, Power4 } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

let Scroller = {
	_scrolling: false,
	duration: 1,
	scrollTo: function(target, delay, offsetY, duration) {
		if (this._scrolling) {
			return;
		}
		let targetValue;
		let type = typeof target;
		if (type === 'number') {
			targetValue = (target < 0) ? 0 : target;
		} else if (type === 'string') {
			if (target === '#') {
				targetValue = 0;
			} else {
				targetValue = target;
			}
		} else {
			return;
		}
    delay = isNaN(delay) ? 0 : delay;
    offsetY = isNaN(offsetY) ? 0 : offsetY;
		duration = isNaN(duration) ? this.duration : duration;
		gsap.to(window, {
			duration: duration,
      delay: delay,
			scrollTo: {y: targetValue, offsetY: offsetY},
			ease: Power4.easeOut,
			onComplete: this._onScrollComplete.bind(this)
		});
		this._scrolling = true;
	},
	_onScrollComplete: function() {
		this._scrolling = false;
	},
	dispose: function() {
		this._scrolling = null;
		this.duration = null;
	}
};
Object.defineProperties(Scroller, {
	'scrolling': {
		get: function() {
			return this._scrolling;
		}
	}
});

export default Scroller;

import * as React from "react";
import { Row, Col } from 'react-bootstrap';

const ContactSent = ({ isFailed }) => {

  return (
    <Row>
      <Col>
        {!isFailed ?
          <p className="result">
            送信が完了しました。お問い合わせ頂き、誠にありがとうございます。<br />
            返信にはお時間を頂戴する場合がございますので、予めご了承下さい。
          </p>
          :
          <p className="result text-danger">
            正常に送信できませんでした。<br />
            恐れ入りますが、上記のメールアドレスまたはお電話にてお問い合わせ下さい。
          </p>
        }
      </Col>
    </Row>
  );
};

export default ContactSent;

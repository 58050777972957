import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Seo from "../components/seo";
import Layout from "../components/layout";
import ContactStep from "../components/parts/contact-step";
import ContactForm from "../components/parts/contact-form";
import ContactConfirm from "../components/parts/contact-confirm";
import ContactSend from "../components/parts/contact-send";
import ContactSent from "../components/parts/contact-sent";

import "../styles/global/base.scss";
import "../styles/contact.scss";

import Mail from "../svgs/mail.svg";
import Call from "../svgs/call.svg";

const defaultValues = {
  name: '',
  mail: '',
  phone: '',
  body: '',
  checked: false
};

const contactReducer = (state, action) => {
  switch (action.type) {
    case 'input':
    case 'send':
    case 'sent':
    case 'failed':
      state = Object.assign({}, state, {
        step: action.type
      });
      break;
    case 'confirm':
      state = Object.assign({}, state, {
        step: action.type,
        values: action.values
      });
      break;
    default:
      break;
  }
  return state;
};

const ContactPage = () => {

  const initialState = {
    step: 'input',
    values: defaultValues
  };
  const [contactState, contactAction] = React.useReducer(contactReducer, initialState);
  const confirmAction = React.useCallback((values) => contactAction({ type: 'confirm', values: values }), []);
  const inputAction = React.useCallback(() => contactAction({ type: 'input' }), []);
  const sendAction = React.useCallback(() => contactAction({ type: 'send' }), []);
  const sentAction = React.useCallback(() => contactAction({ type: 'sent' }), []);
  const failedAction = React.useCallback(() => contactAction({ type: 'failed' }), []);

  const ContactContent = ({ step }) => {
    const values = contactState.values;
    if (step === 'input') {
      return <ContactForm values={values} confirmAction={confirmAction} />;
    } else if (step === 'confirm') {
      return (
        <GoogleReCaptchaProvider reCaptchaKey="6Lf0OgoeAAAAAHyOxZfGSbeWhj2b8a0FqqwbI7M2" language="ja">
          <ContactConfirm values={values} inputAction={inputAction} sendAction={sendAction} />
        </GoogleReCaptchaProvider>
      );
    } else if (step === 'send') {
      return <ContactSend values={values} sentAction={sentAction} failedAction={failedAction} />;
    } else if (step === 'sent') {
      return <ContactSent />;
    } else {
      return <ContactSent isFailed />;
    }
  };

  return (
    <React.Fragment>
      <Seo title="CONTACT" pageClass="contactPage" />
      <Layout>
        <Container fluid className="contactHeader">
          <Row>
            <Col>
              <h1>CONTACT</h1>
              <div>
                <p>弊社へのお問い合わせは以下のフォーム、またはメール・お電話にてご連絡ください。</p>
                <a href="mailto:desk@montage.co.jp"><div className="mailIcon icon"><Mail /></div>desk@montage.co.jp</a>
                <div className="callIcon icon"><Call /></div>03-3303-9871
              </div>
              <div className="cautionText"><span className="caution">※</span>は必須項目です。</div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="contactBody">
          <ContactStep step={contactState.step} />
          <ContactContent step={contactState.step} />
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export default ContactPage;

import * as React from "react";
import { Row, Col } from 'react-bootstrap';

const ContactStep = ({ step }) => {

  return (
    <Row className="steps" id="contactStep">
      <Col xs={4} className={(step === 'input') ? 'current' : ''}>
        内容の入力
      </Col>
      <Col xs={4} className={(step === 'confirm') ? 'current' : ''}>
        内容の確認
      </Col>
      <Col xs={4} className={(step === 'sent') ? 'current' : ''}>
        送信完了
      </Col>
    </Row>
  );
};

export default ContactStep;

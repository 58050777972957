import * as React from "react";
import { Row, Col } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from "axios";

import Scroller from "../../lib/scroller";
import Arrow from "../../svgs/arrow.svg";

const recaptchaThreshold = 0.2;
const actionURL = 'https://montage.co.jp/php-mail/recaptcha-verify.php';

const ContactConfirm = ({ values, inputAction, sendAction }) => {

  const [verifingState, setVerifingState] = React.useState('waiting');
  const [token, setToken] = React.useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const SendButton = () => {
    if (!executeRecaptcha) {
      return null;
    } else if (verifingState === 'waiting') {
      return <button onClick={sendButtonClickHandler}>送信する<div className="arrowIcon"><Arrow color="#fff" /></div></button>;
    } else if (verifingState === 'verifing') {
      return <p className="verifing text-center">検証中...</p>
    } else {
      return null;
    }
  };

  const getToken = React.useCallback(async () => {
    const token = await executeRecaptcha('homepage');
    setToken(token);
  }, [executeRecaptcha]);

  const sendButtonClickHandler = () => {
    if (!executeRecaptcha || verifingState !== 'waiting') {
      return;
    }
    setVerifingState('verifing');
    getToken();
  };

  React.useEffect(() => {
    let unmounted = false;
    const source = axios.CancelToken.source();
    if (!!token) {
      const data = new FormData();
      data.append('token', token);
      axios.post(actionURL, data, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        responseType: 'text',
        timeout: 5000,
        cancelToken: source.token
      })
      .then((result) => {
        //console.log('then: ', result);
        if (!unmounted) {
          const resultData = JSON.parse(JSON.stringify(result.data));
          if (!!resultData.success) {
            if (recaptchaThreshold < resultData.score) {
              sendAction();
            } else {
              setVerifingState('failed');
            }
          } else {
            setVerifingState('failed');
          }
        }
      })
      .catch(() => {
        //console.log('error: ', error);
        if (!unmounted) {
          setVerifingState('failed');
        }
      });
    }
    return (() => {
      unmounted = true;
      source.cancel();
    });
  }, [token, sendAction]);

  React.useEffect(() => {
    Scroller.scrollTo('#contactStep', 0.1, 200, 0);
  }, []);

  return (
    <React.Fragment>
      <Row className="confirm">
        <Col md={{ span: 8, offset: 2 }} as="dl">
          <dt>お名前：</dt>
          <dd>{values.name}</dd>
          <dt>メールアドレス：</dt>
          <dd>{values.mail}</dd>
          <dt>電話番号：</dt>
          <dd>{values.phone}</dd>
          <dt>お問い合わせ内容：</dt>
          <dd>{values.body}</dd>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <Row>
            <Col lg={6}>
              <button onClick={inputAction}>入力に戻る<div className="arrowIcon"><Arrow color="#fff" /></div></button>
            </Col>
            <Col lg={6}>
              <SendButton />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <p className="small">
                このフォームは Google reCAPTCHA で保護されています。<br />
                <a href="https://www.google.com/intl/ja/policies/privacy/" target="_blank" rel="noreferrer">プライバシー</a> - <a href="https://www.google.com/intl/ja/policies/terms/" target="_blank" rel="noreferrer">利用規約</a>
              </p>
              {verifingState === 'failed' &&
                <p className="small text-danger">
                  Google reCAPTCHA によりボットと判定されました。<br />
                  恐れ入りますが、上記のメールアドレスまたはお電話にてお問い合わせ下さい。
                </p>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ContactConfirm;

import * as React from "react";
import { Link } from "gatsby";
import { Row, Col, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";

import Arrow from "../../svgs/arrow.svg";

const ContactForm = ({ values, confirmAction }) => {

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: values
  });

  const confirm = (data) => {
    confirmAction(data);
  };

  return (
    <Form>
      <Row>
        <Col lg={4}>
          お名前 <span className="caution">※</span>
        </Col>
        <Col lg={8}>
          <input
            type="text"
            {...register("name", {
              required: true
            })}
          />
          {errors.name?.type === 'required' &&
            <p className="error">お名前を入力して下さい。</p>
          }
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          メールアドレス <span className="caution">※</span>
        </Col>
        <Col lg={8}>
          <input
            type="mail"
            {...register("mail", {
              required: true,
              pattern: /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
            })}
          />
          {errors.mail?.type === 'required' &&
            <p className="error">メールアドレスを入力して下さい。</p>
          }
          {errors.mail?.type === 'pattern' &&
            <p className="error">メールアドレスの形式が正しくないようです。</p>
          }
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          電話番号 <span className="caution">※</span>
        </Col>
        <Col lg={8}>
          <input
            type="tel"
            {...register("phone", {
              required: true,
              pattern: /^[0-9０-９+\-()（）\s　内線]{10,}$/
            })}
          />
          {errors.phone?.type === 'required' &&
            <p className="error">電話番号を入力して下さい。</p>
          }
          {errors.phone?.type === 'pattern' &&
            <p className="error">文字数が足らないか、使用できない文字が含まれています。</p>
          }
        </Col>
      </Row>
      <Row className="explain">
        <Col lg={4}>
          お問い合わせ内容 <span className="caution">※</span>
        </Col>
        <Col lg={8}>
          <textarea
            {...register("body", {
              required: true
            })}
          />
          {errors.body?.type === 'required' &&
            <p className="error">お問い合わせ内容を入力して下さい。</p>
          }
        </Col>
      </Row>
      <Row className="privacy">
        <Col lg={{ span: 8, offset: 4 }}>
          <input
            type="checkbox"
            className="form-check-input"
            {...register("checked", {
              required: true
            })}
          />
          「<Link to="/privacy-policy/">プライバシーポリシー</Link>」をご確認頂き、同意の上お問い合わせください。
          {errors.checked?.type === 'required' &&
            <p className="error">プライバシーポリシーをご確認の上、チェックを入れて下さい。</p>
          }
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 4, offset: 4 }}>
          <button onClick={handleSubmit(confirm)}>入力内容を確認する<div className="arrowIcon"><Arrow color="#fff" /></div></button>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactForm;
